import * as React from "react";
import Helmet from "react-helmet";
import { Clients, Feature, Footer, Form } from "../components";
// import { setNav } from "../components/utils";

const soundImg = require("../assets/images/pg_qoute.jpg");

const seo = {
  title: `Quote`,
  description: `A couple of details is all we need to get you a quote.`,
}

// type Props = {};
export default () => {
  return (
    <div className="quotePage">
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description}/>
        <meta name="twitter:description" content={seo.description} />
      </Helmet>
      <Feature.Mast
        title={seo.description}
        image={soundImg}
      />
      <Form.Quote />
      <Clients.Min dark={true} />
      <Footer />
    </div>
  );
};
